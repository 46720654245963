@font-face {
    font-family: 'Nexa-Bold';
    src: local('Nexa-Bold'), url(../assets/fonts/Nexa-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Nexa-Light';
    font-weight: 900;
    src: local('Nexa-Light'), url(../assets/fonts/Nexa-Light.otf) format('opentype');
}



* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Nexa-Light';
}

body{
    background-image: url(../assets/img/analisa5Transparent.png);
    background-repeat: no-repeat;
    background-size: 100%;
}

button {
    border: none;
    outline: none;
    width: 100%;
    text-align: center;
    padding: 15px 0;
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
    /*background: #603bbb;*/
    cursor: pointer;
}

.login {
    width: 100%;
    min-height: 80vh;
    padding: 0 20px;
    display: flow;
    background-image: url(../assets/img/analisa5.png);
    background-repeat: no-repeat;
    background-size: 100%;


}

.login .loginContainer {
    padding: 60px;
    margin: auto;
    width: 100%;
    max-width: 450px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: radial-gradient(
            ellipse at left bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 22, 101, 0) 59%,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 0%
    );
    box-shadow: 0 50px 70px -20px rgba(128, 128, 129, 10);
}

.login .loginContainer label {
    color: #3a2b58;
    margin: 14px 0;
    display: block;
    font-size: 22px;
    line-height: 1;
}

.login .loginContainer input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 19px;
    padding: 10px;
    background: rgba(231, 41, 85, 100) 100%;
    color: rgba(255, 255, 255, 100);
    letter-spacing: 1px;
}

.login .loginContainer .btnContainer {
    width: 100%;
    padding: 24px 0;
}

.login .loginContainer .btnContainer p {
    margin: 14px 0 0 0;
    text-align: right;
    color: #fff;
}

.login .loginContainer .btnContainer p span {
    color: yellow;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-left: 5px;
    cursor: pointer;
    transition: all 400ms ease-in-out;
}

.login .loginContainer .btnContainer p span:hover {
    color: #3a2b58;
}

.login .loginContainer .errorMsg {
    color: #42453C;
    font-size: 16px;
}

.hero {
    width: 100%;
    min-height: 100vh;
    background: radial-gradient(
            ellipse at left bottom,
            rgba(231, 41, 85, 1) 0%,
            rgba(155, 22, 101, 0.9) 59%,
            rgba(239, 124, 75, 0.9) 100%
    );
}

.hero nav {
    background: #603bbb;
    width: 100%;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hero nav h2 {
    color: #3a2b58;
}

.hero nav button {
    width: 86px;
    padding: 8px 0;
    background: #3a2b58;
}

.active {
    border: solid 3px #E62954;
    border-radius: 10px;
    background-color: #E62954;
}

/*.Nav {*/
/*    border-bottom-width: 1px;*/
/*    border-color: #E62954;*/
/*    background-color: #fff;*/
/*    width: 100%;*/
/*    position: fixed;*/
/*    height: 5rem;*/
/*    z-index: 1;*/
/*    bottom: auto;*/
/*}*/

.Nav {
    border-bottom-width: 1px;
    border-color: #dae1e7;
    background-color: #fff;
    width: 100%;
    position: fixed;
    height: 4rem;
    z-index: 1;
    margin-top: -18px;
}

.ErrorContainer {
    border-width: 1px;
    position: fixed;
    z-index: 50;
    background-color: #fcebea;
    border-color: #ef5753;
    margin-top: 4rem;
    width: 100%;
    color: #cc1f1a;
    border-radius: 0.25rem;

}

.Error__inner {
    -webkit-align-items: center;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    max-width: 50rem;
    margin-right: auto;
    left: 50%;
}

.Error__button,
.Error__inner {
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
}

.block {
    display: block;
}

.Error__icon {
    color: #e3342f;
}

.Error__icon:hover {
    color: #cc1f1a;
}

.Perfil__boton-logout {
    border-width: 1px;
    border-color: #603bbb;
    border-radius: 0.125rem;
    text-align: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: 0.5rem;
    background-color: #603bbb;
}

@media (min-width: 576px) {
    .Perfil__boton-logout {
        margin-left: 1rem;
        margin-top: 0;
    }
}


/* bouncing balls */
.bouncer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100px;
    height: 100px;
    max-width: 100px;
    margin: 200px auto;
    display: flex;
    justify-content: space-around;
}

.bouncer div {
    width: 20px;
    height: 20px;
    background: #E62954;
    border-radius: 50%;
    animation: bouncer 0.5s cubic-bezier(.19, .57, .3, .98) infinite alternate;
}

.bouncer div:nth-child(2) {
    animation-delay: 0.1s;
    opacity: 0.8;
}

.bouncer div:nth-child(3) {
    animation-delay: 0.2s;
    opacity: 0.6;
}

.bouncer div:nth-child(4) {
    animation-delay: 0.3s;
    opacity: 0.4;
}

@keyframes bouncer {
    from {
        transform: translateY(0)
    }
    to {
        transform: translateY(-100px)
    }
}


/* spinner */
.spinner {
    width: 60px;
    height: 60px;
    position: fixed;
    margin-left: 40%;

}
.spinner div {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 8px solid transparent;
    border-top-color: #E62954;
    border-radius: 50%;
    animation: spinnerOne 1.2s linear infinite;
}
.spinner div:nth-child(2) {
    border: 8px solid transparent;
    border-bottom-color: #0000ff;
    animation: spinnerTwo 1.2s linear infinite;
}

@keyframes spinnerOne {
    0% { transform: rotate(0deg); border-width: 10px; }
    50% { transform: rotate(180deg); border-width: 1px; }
    100% { transform: rotate(360deg); border-width: 10px; }
}
@keyframes spinnerTwo {
    0% { transform: rotate(0deg); border-width: 1px; }
    50% { transform: rotate(180deg); border-width: 10px; }
    100% { transform: rotate(360deg); border-width: 1px; }
}



.vertical-center-modal .ant-btn {

    text-align: center;
    height: 10%;
    width: 10%;
}

